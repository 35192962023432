@charset "UTF-8";

@import url("normalize.css");
@import url("marginpadding-helper.css");
@import url("vendor/baguetteBox.css");

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 62.5%; /* (62.5/100) * 16px = 10px */
  --font-size--small: 1.4rem; /* 14px */
  --font-size--default: 1.6rem; /* 16px */
  --font-size--large: 2.4rem; /* 24px */

  --color-red: #C1002C;
  --color-dark-red: #74001a;
  --color-yellow: #F3D259;
  --color-light-yellow: #FFF1BE;
  --color-green: #BCCF00;

  --social-icon-size: 32px;
}

body {
  font-size: var(--font-size--default);
  line-height: 1.5em;

  background: #EFEFEF;

  position: relative;
  display: flex;
  flex-flow: column;
  min-height: 100vh
}

main {

}

hr {
  border: none;
  border-top: 5px solid #dfdfdf;
  margin: 2rem 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  line-height: 1.15;
  color: var(--color-red);

  display: inline-block;
}

a {
  color: inherit;
  text-decoration-color: var(--color-red);
}

main a[href*="//"]:not([href*="owbib"]):not([href*="eopac"]):not([href*="127.0.0.1"]):not([href*="localhost"]):after  {
    content: '\00a0\2197';
}

main a.no-arrow:after {
  content: '' !important; /* Die Regeln oben übertrumpfen */
}

h1 {
  padding-bottom: 8px;
  border-bottom: 4px solid var(--color-yellow);
}

h2 {
  padding-bottom: 8px;
}

.inner {
  margin: 0 auto;
  padding: 0 2rem;
}

@media screen and (min-width: 896px) {
  .inner {
    width: 80%;
    max-width: 100rem;
  }
}

@media screen and (min-width: 896px) {
  .grid {
    display: grid;
  }
}

.with-2-cols {
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}

.with-3-cols {
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
}

.with-sidebar {
  grid-template-columns: auto 25rem;
  column-gap: 2rem;
}




header {
  background: var(--color-red);
  color: #FFFFFF;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;

  z-index: 100;

  position: relative;
}

.header--top {
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.header--logo {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  text-decoration: none;
}

.header--logo img {
  height: 6rem;
  width: auto;
  margin-right: 2rem;
}

.header--logo h1 {
  display: block;
  color: #FFFFFF;
  border: 0;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 3rem;
  text-shadow: 0px 2px 4px rgba(0,0,0,0.4);
}

.header--logo a {
  text-decoration: none;
}

.header--logo h2 {
  display: none;
}

@media screen and (min-width: 576px) {
  .header--logo h2 {
    display: block;
    color: #F8E0E5;
    font-style: italic;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
  }
}

@media screen and (min-width: 576px) {
  header {
    margin-bottom: 4rem;
  }
}


.header--fridolin {
  display: none;
}

@media screen and (min-width: 1120px) {
  .header--fridolin {
    display: block;
    margin-bottom: -6rem;
  }
}



.header--navigation {
  background: var(--color-dark-red);
  /*border-bottom: 1rem solid var(--color-yellow);*/
}

.header--navigation nav {
}

.header--navigation ul, .header--navigation li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header--navigation ul {
  display: flex;
  flex-wrap: wrap;
}

.header--navigation li:first-of-type {
  display: none;
  visibility: hidden;
}

.header--navigation li {
  flex: 1 0 33%;
  text-align: center;
}

@media screen and (min-width: 672px) {
  .header--navigation {
    border-bottom: 1rem solid var(--color-yellow);
  }

  .header--navigation li {
    flex: inherit;
    text-align: inherit;
  }

  .header--navigation li:first-of-type {
    display: inherit;
    visibility: inherit;
  }
}

.header--navigation a {
  display: block;
  color: #FFFFFF;
  text-decoration: none;
  padding: 4px 12px;
  transition: background .2s;
}

.header--navigation a:hover {
  background: #9a0023;
}

.header--navigation .is-active a {
  background: var(--color-yellow);
  color: #3c3416;
}

.header--navigation .home svg{
  display: block;
  height: 16px;
  width: auto;
  margin-top: 4px;
}

.header--navigation .is-active .home path {
  fill: var(--color-dark-red) !important;
}

footer {
  background: var(--color-yellow);
  color: #3c3416;
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 4rem;
  border-top: 15px solid var(--color-red);
  z-index: 60;
  /*position: relative;*/
}

@media screen and (min-width: 576px) {
  footer .inner {
    display: flex;
    width: 100%;
  }
}

footer .logo svg {
  width: 18rem;
  height: auto;
  padding-right: 4rem;
}

footer .address {
  flex: 1;
}

footer hr {
  border-color: var(--color-red);
}

main {
  z-index: 50;
  flex: 1;
}

main .inner {
  background: #FFFFFF;
  box-shadow: 0 0 8rem 8rem rgba(255,255,255,1);
}

.main--sidebar h2, .main--mobile-navigation h2 {
  display: block;
  margin-bottom: 1.5rem;
  border-bottom: 4px solid var(--color-yellow);
}

.main--sidebar h2 a, .main--mobile-navigation h2 a {
  text-decoration: none;
}

.main--mobile-navigation {
  margin-bottom: 3rem;
  padding-bottom: 1.5rem;
  border-bottom: 4px solid var(--color-yellow);
}

@media screen and (min-width: 896px) {
  .main--mobile-navigation {
    display: none;
    visibility: hidden;
  }
}

.main--mobile-navigation select {
  width: 100%;
  display: block;
}

.main--sidebar {
  display: none;
  visibility: hidden;
}

@media screen and (min-width: 896px) {
  .main--sidebar {
    display: block;
    visibility: visible;
  }
}

.main--navigation ul, .main--navigation li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main--navigation ul {
  margin-bottom: 1rem;
}

.main--navigation ul ul {
  padding-left: 12px;
}

.main--navigation li {
  margin-top: 1px;
}

.main--navigation a {
  display: block;
  background: var(--color-light-yellow);
  text-decoration: none;
  padding: 4px 12px;
  transition-property: background, opacity;
  transition-duration: .2s;
}

.main--navigation a:hover {
  background: var(--color-yellow);
}

.main--navigation .is-open {
  background: var(--color-yellow);
}

.main--navigation .is-active {
  background: var(--color-red);
  color: #fff;
}

.main--navigation .is-active:hover {
  background: var(--color-red);
  opacity: 0.9;
}

.block {
  margin-bottom: 4rem;
}

.text {
  width: 100%;
}

.with-background {
  padding: 1.5rem 2rem;
}

.with-background .text > h2 {
  padding-top: 0;
}

.with-background.lightgreen {
  background: #FAFFC9;
}

.with-background.green {
  background: var(--color-green);
}

.with-background.lightred {
  background: #FFE4E4;
}

.with-background.red {
  background: var(--color-red);
  color: #FFFFFF;
}

.with-background.red a {
  color: var(--color-yellow);
}

.with-background.red a:hover {
  color: var(--color-light-yellow);
}

.with-background.yellow {
  background: var(--color-yellow);
}

.with-background.lightyellow {
  background: #FFF6D4;
}

.with-background.gray {
  background: #D9D9D9;
}

.with-image {}

@media screen and (max-width: 575px) {
  .with-image figure {
    width: 100% !important;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 576px) {
  .with-image--left figure {
    float: left;
    margin-right: 2rem;
    marign-bottom: 1rem;
  }

  .with-image--right figure {
    float: right;
    margin-left: 2rem;
    marign-bottom: 1rem;
  }
}

.with-image figure img {
  width: 100%;
  height: auto;
  display: block;
}

.with-image .text {
  flex: 1 1 auto;
}

.block.headline {
  margin-bottom: 0;
  line-height: 1.3
}

.text h2, .text h3, .text h4, .text h5 {
  display: block;
  padding-top: 0;
  padding-bottom: 1rem;
  line-height: 1.3;
}

.text h4, .text h5 {
  color: var(--color-dark-red);
}

.text a {
  color: var(--color-red);
  text-decoration-color: var(--color-dark-red);
}

.text a:hover {
  color: var(--color-dark-red);
}

.text p {
  margin-bottom: 1rem;
}

.text p:last-of-type {
  margin-bottom: 0;
}

.text ul, .text ol {
  padding-left: 4rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.text table {
  margin: 0 auto;
}

.table--fullwidth table {
  width: 100%;
  margin: 0 -6px;
}

.table--align-first-column table td:first-of-type {
  width: 25%;
}

.table--margin-bottom table {
  margin-bottom: 1rem;
}

.text table td {
  padding: 2px 4px;
}

.text figure.inline {
  display: inline-block;
  width: 6rem;
}

.text figure.inline img {
  width: 100%;
  height: auto;
}

.text--right {
  text-align: right;
}

.image {
  display: block;
  max-width: 100%;
  width: 90%;
  height: auto;
  margin: 0 auto;
}

.image img {
  display: block;
  width: 100%;
  height: auto;
}

.image--left {
  margin: 0 0;
}

.image--right {
  margin: 0 0 0 auto;
}

figcaption {
  padding-top: 4px;
  text-align: right;
  font-style: italic;
  color: #444444;
  font-size: 0.85em;
}

.gallery {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

@media screen and (min-width: 576px) {
  .gallery {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.gallery img {
  display: block;
  width: 100%;
  height: auto;
}

.image > a, .gallery figure a {
  display: block;
  position: relative;
}

.image > a.with-lightbox::before, .gallery figure a::before {
  content: "🔍";
  font-size: 3rem;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  opacity: 0.4;
  transition: opacity ease-in-out .2s;
}

.image > a:hover::before, .gallery figure a:hover::before {
  opacity: 1;
}

.video {
  position: relative;
}

.video iframe {
  width: 100%;
  min-height: 450px;
}

.video img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  display: block;
  margin: 0;
}

.video--overlay {
  position: relative;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px 40px;

  background: rgba(230,230,230,0.9);
}

.video--overlay .button {
  margin: 12px 0;
}

.block nav li:nth-child(5n) {
  margin-bottom: 1rem;
}

blockquote {
  display: block;
  font-family: serif;
  font-style: italic;
  font-size: 1.6rem;

  text-align: center;

  margin: 2rem 0;
  padding: 1rem 12px;

  background: #FAFFC9;
  color: #3C4200;
}

@media screen and (min-width: 576px) {
  .block blockquote {
    max-width: 85%;
    margin: 0rem auto 3rem auto;
  }
}

blockquote .source {
  border-top: 1px solid var(--color-green);
  padding-top: 1rem;
  margin-top: 1rem;
  font-style: normal;
  font-size: 1.3rem;
}

blockquote .source a {
  text-decoration-color: var(--color-green);
}

.eopac-embedded-openings {
  text-align: center;
}

.eopac-embedded-openings-regular {
  margin: 0 auto;
}

.eopac-embedded-openings-regular td:first-child {
  text-align: right;
  padding-right: 5px;
}

.eopac-embedded-openings-regular td:last-child {
  text-align: left;
  padding-left: 5px;
}

.eopac-embedded-closings, .eopac-embedded-specialopenings {
  margin-top: 2rem;
  text-align: center;
}

.eopac-embedded-closings ul, .eopac-embedded-specialopenings ul {
  list-style: none;
}

.eopac-embedded-simplelist-element a {
  display: block;
  color: #888888;
  text-decoration: none;
  padding-bottom: 0.5rem;
}

.eopac-embedded-simplelist-element strong {
  display: block;
  text-decoration: underline;
  color: var(--color-red);
}

.home--item {
  margin-bottom: 2rem;
}

.home--item:last-of-type {
  margin-bottom: 0;
}

.home--item h4 span {
  display: inline-block;
  color: var(--color-red);
  padding-right: 1rem
}

.greeting--advent {
  width: 90%;
  margin-bottom: 2rem;
}

@media screen and (min-width: 576px) {
  .greeting--advent {
    width: 80%;
  }
}


.greeting--advent img {
  width: 100%;
  height: auto;
}

.greeting {
  text-align: center;
}

.greeting .grid {
  grid-template-columns: 200px auto;
  align-items: center;
  column-gap: 2rem;
}

.greeting img {
  display: block;
  width: 140px;
  height: auto;
  margin: 0 auto;
}

.pagination a {
  display: block;
  text-align: center;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 576px) {
  .pagination {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
  }

  .pagination .prev {
    grid-column-start: 1;
    text-align: left;
  }

  .pagination .up {
    grid-column-start: 2;
  }

  .pagination .next {
    grid-column-start: 3;
    text-align: right;
  }
}

@media screen and (min-width: 576px) {
  .index {
    column-count: 3;
  }

  .index ul {
    overflow: inherit;
  }

  .index .depth-1 {
    break-inside: avoid-column;
    page-break-inside: avoid;
  }
}

.app-badges {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.app-badges a:after {
  content: '' !important; /* Übertrumpfen der Link-Pfeile */
}

.app-badges img {
  margin: 0 1rem;
  width: 160px;
  height: auto;
}

.faq {}

.faq--divider {
  margin-top: 4rem;
  text-align: center;

  position: relative;
}

.faq--divider::after, .faq--divider::before {
  position: absolute;
  display: block;
  content: '';

  border-top: 5px solid #dfdfdf;
  width: 45%;
  top: 50%;
  margin-top: -8px;
}

.faq--divider::before {
  left: 0;
}

.faq--divider::after {
  right: 0;
}

.social-links {
  text-align: right;
}

.social-links a {
  text-decoration: none;
  display: inline-block;
  height: calc(var(--social-icon-size) + 8px);
  width: calc(var(--social-icon-size) + 8px);
}

.social-links a:after {
  content: '' !important;
}

.social-links svg {
  height: var(--social-icon-size);
  width: auto;
  display: block;
  margin: 4px auto;

}

.social-bibkat {
  background-color: #495994;
}

.social-facebook {
  background-color: #1877F2;
}

.social-twitter {
  background-color: #1DA1F2;
}

.social-telegram {
  background-color: #2CA5E0;
}

.social-youtube {
  background-color: #FF0000;
}

.social-instagram {
  background-color: #E4405F;
}

.search--form {
  display: flex;
}

.search--form .input {
  flex: 1;
  margin-right: 1rem;
}

.search--form .button {
  flex: 0;
}

.search--results {
  margin-top: 2rem;
  margin-left: 2rem;
}

.search--results li {
  margin-bottom: 0.5rem;
  transition: margin ease-in-out .4s;
}

.search--results li:hover {
  margin-left: 2rem;
}

.search--results a {
  display: block;
  text-decoration: none;
}

.search--results .title {
  text-decoration: underline;
  text-decoration-color: var(--color-red);
}

.search--results .url {
  font-size: 1rem;
  color: #808080;
  display: block;
  text-decoration: none;
}

.newsletter--form {
  text-align: center;
}

.newsletter--form .input, .newsletter--form .button {
  margin-bottom: 1rem;
}

.input {
  padding: 8px;
  border: 4px solid var(--color-red);
  box-shadow: 4px 4px 0px var(--color-yellow);
  transition: border-color ease-in-out .2s;
}

.input:hover {
  border-color: var(--color-dark-red);
}

.input:focus {
  outline-offset: 0;
  outline: 4px solid var(--color-light-yellow);
}

.button {
  border: none;
  outline: none;
  background: var(--color-red);
  color: #fff;
  padding: 12px;
  box-shadow: 4px 4px 0px var(--color-yellow);

  cursor: pointer;

  transition: background ease-in-out .2s;

  position: relative;
}

.button:hover {
  background: #D54364;
}

.button:active {
  box-shadow: none;
  top: 2px;
}
